import React, {lazy, Suspense, useEffect, useRef, useState} from 'react';
import {BrowserRouter, Routes, Route, Link, useNavigate, ScrollRestoration, useLocation} from 'react-router-dom'
import {Helmet, HelmetProvider} from 'react-helmet-async';
import './App.css';
import './styles/Common.css';
import 'animate.css/animate.min.css';

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ResearchHighlights from "./components/ResearchHighlights";
import Services from "./components/Services";
// import Blog from "./components/Blog";
// import BlogPost from "./components/BlogPost";
// import Structure from "./components/Structure";
// import Publications from "./components/Publications";
import People from "./components/People";
import Examiner from "./components/Examiner";
import FoundationalModel from "./components/FoundationalModel";
import AssessmentSystem from "./components/AssessmentSystem";
import Article from './components/Article';
import About from "./components/About";
import Bottombar from "./components/Footer";
import ScrollToTopOnMount from './hooks/ScrollToTopOnMount';
import ArticlesOuter from "./components/ArticlesOuter";
import Paper from "./components/Paper"; // Import the component

// const Chat = lazy(() => import("./components/Chat"));

const App = () => {

    const renderNavbarAndBottombar = () => {
            return (
                <>
                    {/*<ScrollRestoration/>*/}
                    <ScrollToTopOnMount/>
                    <Navbar />
                    <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/research-highlights" element={<ResearchHighlights/>}/>
                        <Route path="/services" element={<Services/>}/>
                        {/*<Route path="/mind-engine" element={<MindEngine/>}/>*/}
                        {/*<Route path="/superalignment" element={<Superalignment/>}/>*/}
                        {/*<Route path="/blog" element={<Blog/>}/>*/}
                        {/*<Route path="/blog-post" element={<BlogPost/>}/>*/}
                        {/*<Route path="/structure" element={<Structure/>}/>*/}
                        {/*<Route path="/publications" element={<Publications/>}/>*/}
                        <Route path="/ai-ethics-and-safety-assessment-system" element={<AssessmentSystem/>}/>
                        <Route path="/large-ai-models-safety-and-ethics-examiner" element={<Examiner/>}/>
                        <Route path="/safe-ai-foundational-model" element={<FoundationalModel/>}/>
                        <Route path="/people" element={<People/>}/>
    {/*                    <Route path="/articles" element={<ArticlesOuter />} >
                            <Route index element={<People />} />
                            <Route path=":slug" element={<Article />} />
                        </Route>*/}
                        <Route exact path="/articles/:slug" element={<Article />} />
                        {/*<Route exact path="/articles/StressPrompt" element={<Paper />} />*/}
                        <Route exact path="/beijing-institute-of-ai-safety-and-governance-launch-announcement" element={<Article/>} />
                        <Route path="/about" element={<About/>}/>
                    </Routes>
                    </Suspense>
                    <Bottombar/>
                </>
            );

    };

    return (
        <HelmetProvider>
            <BrowserRouter>
                {renderNavbarAndBottombar()}
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App
